@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/tokens';
@import '@teliads/components/foundations/typography/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.the-footer {
  .legalrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $telia-spacing-4;

    &__legal-text {
      text-align: left;
      flex: 1;
    }

    .consent-list {
      list-style: none;
      display: flex;
      gap: $telia-spacing-8;
      flex-direction: column;

      @include telia-breakpoint('md') {
        flex-direction: row;
        gap: $telia-spacing-16;
      }

      &__cookie-settings {
        padding: 0;
        font-family: $telia-typography-telia-sans-font-family;
        font-weight: bold;
        color: $telia-purple-300;
        text-decoration: underline;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1rem;

        @include telia-breakpoint('lg') {
          font-size: 0.875rem;
        }
      }

      &__item-value {
        font-weight: bold;
        color: $telia-purple-300;
      }
    }
  }
  &__logo {
    width: $telia-spacing-80;
  }
}
